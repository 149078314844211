
import { defineComponent } from "vue";
import Tallsekvens from "./components/Tallsekvens.vue";

export default defineComponent({
  name: "App",
  components: {
    Tallsekvens,
  },
});
