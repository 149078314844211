
import { defineComponent } from "vue";

export default defineComponent({
  name: "Tallsekvens",
  data() {
    return {
      index: 0,
      charSequence: "0AGMSY1BHNTZ2CIOUÆ3DJPVØ4EKQWÅ5FLRX",
      result: "0",
      interval: 0,
    };
  },
  created() {
    this.startSequence();
  },
  methods: {
    startSequence(): void {
      this.interval = setInterval(() => {
        this.getNextChar(this.index);
      }, 1000);
    },
    getNextChar(idx: number): void {
      const len = this.charSequence.length;
      if (len === idx || idx >= len) {
        this.index = 0;
        this.result = this.charSequence[0];
      } else {
        this.result = this.charSequence[idx];
        this.index = this.index + 1;
      }
    },
  },
});
